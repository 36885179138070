
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import { commonGalleryImages } from "@/core/directive/function/order";
import mixin from "@/mixins";
import { ApiDsSku } from "@/core/api";
import _ from "lodash";
import { AttachmentItem } from "@/core/directive/interface/dsSkuManagement";

interface exceptionInvoiceParams {
  id: number;
  attachment_items: Array<AttachmentItem>;
  is_exception: number;
  notes: string;
  rowErrorBorder?: boolean;
}

export default defineComponent({
  name: "report-invoice-exception",
  components: {
    ProductInfo,
  },
  props: {
    exceptionData: {
      type: Object as () => exceptionInvoiceParams,
      default: () => {
        return {
          id: 0,
          attachment_items: [],
          is_exception: 20,
          notes: "",
        };
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const reportInvoiceExceptionRef = ref<CommonHtmlType>(null);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const loading = ref<boolean>(false);

    /*
     *拉取数据
     */
    const formData = ref<exceptionInvoiceParams>({
      id: 0,
      attachment_items: [],
      is_exception: 20,
      notes: "",
    });
    const getFormData = () => {
      formData.value = _.cloneDeep(props.exceptionData);
      formData.value.rowErrorBorder = false;
      for (
        let index = 0;
        index < formData.value.attachment_items.length;
        index++
      ) {
        formData.value.attachment_items[index].rowErrorBorder = false;
      }
    };

    /*
     *勾选交互
     */
    const invoiceMissingChange = () => {
      if (formData.value?.is_exception == 20) {
        formData.value.is_exception = 10;
      } else if (formData.value?.is_exception == 10) {
        formData.value.is_exception = 20;
      }
    };
    const formCheckboxChange = (index: number) => {
      if (formData.value?.attachment_items[index].is_exception == 20) {
        formData.value.attachment_items[index].is_exception = 10;
      } else if (formData.value?.attachment_items[index].is_exception == 10) {
        formData.value.attachment_items[index].is_exception = 20;
      }
    };

    /*
     *提交
     */
    const submit = () => {
      loading.value = true;
      const order: any = [];
      let errorFlag = false;
      if (
        formData.value.is_exception == 10 &&
        props.exceptionData.is_exception == 20
      ) {
        // 新增发票缺失
        if (formData.value.notes != "") {
          order.push({
            file_id: "",
            status: "10",
            type: "missing_invoice",
            notes: formData.value.notes,
          });
        } else {
          formData.value.rowErrorBorder = true;
          showValidateErrorMsg();
          errorFlag = true;
        }
      }
      if (
        formData.value.is_exception == 10 &&
        props.exceptionData.is_exception == 10
      ) {
        // 修改发票缺失
        if (formData.value.notes != "") {
          order.push({
            file_id: "",
            status: "10",
            type: "missing_invoice",
            notes: formData.value.notes,
          });
        } else {
          formData.value.rowErrorBorder = true;
          showValidateErrorMsg();
          errorFlag = true;
        }
      }
      if (
        formData.value.is_exception == 20 &&
        props.exceptionData.is_exception == 10
      ) {
        // 取消发票缺失
        order.push({
          file_id: "",
          status: "20",
          type: "missing_invoice",
          notes: "",
        });
      }
      if (formData.value?.attachment_items.length) {
        for (
          let index = 0;
          index < formData.value.attachment_items.length;
          index++
        ) {
          if (
            formData.value.attachment_items[index].is_exception == 10 &&
            props.exceptionData.attachment_items[index].is_exception == 20
          ) {
            // 新增发票异常
            if (formData.value.attachment_items[index].notes != "") {
              order.push({
                file_id: formData.value.attachment_items[index].file_id,
                status: "10",
                type: "invoice",
                notes: formData.value.attachment_items[index].notes,
              });
            } else {
              formData.value.attachment_items[index].rowErrorBorder = true;
              showValidateErrorMsg();
              errorFlag = true;
            }
          }
          if (
            formData.value.attachment_items[index].is_exception == 10 &&
            props.exceptionData.attachment_items[index].is_exception == 10
          ) {
            // 修改发票异常
            if (formData.value.attachment_items[index].notes != "") {
              order.push({
                file_id: formData.value.attachment_items[index].file_id,
                status: "10",
                type: "invoice",
                notes: formData.value.attachment_items[index].notes,
              });
            } else {
              formData.value.attachment_items[index].rowErrorBorder = true;
              showValidateErrorMsg();
              errorFlag = true;
            }
          }
          if (
            formData.value.attachment_items[index].is_exception == 20 &&
            props.exceptionData.attachment_items[index].is_exception == 10
          ) {
            // 取消发票异常
            order.push({
              file_id: formData.value.attachment_items[index].file_id,
              status: "20",
              type: "invoice",
              notes: "",
            });
          }
        }
      }
      if (errorFlag == true) {
        loading.value = false;
        return;
      } else {
        const id = props.exceptionData.id;
        const submitData = {
          id: id,
          order: order,
        };
        ApiDsSku.reportException(submitData)
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              cancel();
              emit("submit");
              showFormSubmitSuccessMsg(() => {
                return;
              });
            } else {
              showServerErrorMsg(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    /*
     *关闭弹框
     */
    const cancel = () => {
      hideModal(reportInvoiceExceptionRef.value);
    };
    const resetForm = () => {
      formData.value = {
        id: 0,
        attachment_items: [],
        is_exception: 20,
        notes: "",
      };
    };
    onMounted(() => {
      modalShowListener(reportInvoiceExceptionRef.value, () => {
        setTimeout(() => {
          getFormData();
        });
      });
      modalHideListener(reportInvoiceExceptionRef.value, () => {
        emit("exit");
        setTimeout(() => {
          resetForm();
        });
      });
    });

    return {
      t,
      props,
      loading,
      reportInvoiceExceptionRef,
      formData,
      commonGalleryImages,
      cancel,
      submit,
      formCheckboxChange,
      invoiceMissingChange,
    };
  },
});
