import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_quick_fill_tool",
  tabindex: "-1",
  "aria-hidden": "true",
  ref: "SearchProductsRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "w-100 d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row w-100" }
const _hoisted_12 = { class: "col-md-3" }
const _hoisted_13 = { class: "py-6" }
const _hoisted_14 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_15 = { class: "fs-4 fw-bold" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "col-md-3" }
const _hoisted_19 = { class: "py-6" }
const _hoisted_20 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_21 = { class: "fs-4 fw-bold" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "col-md-3" }
const _hoisted_25 = { class: "py-6" }
const _hoisted_26 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_27 = { class: "fs-4 fw-bold" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "card-body" }
const _hoisted_31 = ["id", "checked", "onClick"]
const _hoisted_32 = { key: 0 }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "card-footer d-flex flex-wrap justify-content-end flex-max-sm-column max-sm-align-items-end gap-4 py-6 px-9" }
const _hoisted_41 = { class: "svg-icon svg-icon-1" }
const _hoisted_42 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_product_info = _resolveComponent("product-info")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t("dsSkuManagement.SearchProducts")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t("dsSkuManagement.brand")), 1),
                  _createElementVNode("div", _hoisted_15, [
                    (_ctx.searchParams.brand != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.searchParams.brand), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, "--"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t("dsSkuManagement.brandArticleNo")), 1),
                  _createElementVNode("div", _hoisted_21, [
                    (_ctx.searchParams.brand_article_no != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.searchParams.brand_article_no), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_23, "--"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.t("dsSkuManagement.ean")), 1),
                  _createElementVNode("div", _hoisted_27, [
                    (_ctx.searchParams.gtin != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.searchParams.gtin), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_29, "--"))
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_component_MBDatatable, {
              class: "max-sm-text-nowrap",
              loading: _ctx.loading,
              "table-data": _ctx.tableData,
              "table-header": _ctx.tableHeader,
              "enable-items-per-page-dropdown": true,
              "current-page": _ctx.currentPage,
              total: _ctx.total,
              "rows-per-page": _ctx.pageSize,
              onCurrentChange: _ctx.onCurrentPageChange,
              onItemsPerPageChange: _ctx.onRowsPerPageChange
            }, {
              "cell-radio": _withCtx(({ row: productItem }) => [
                (_openBlock(), _createElementBlock("input", {
                  id: productItem.id,
                  key: productItem.id,
                  class: "form-check-input",
                  type: "radio",
                  name: "choose",
                  checked: productItem.id == _ctx.chooseItem?.id,
                  onClick: ($event: any) => (_ctx.chooseChange(productItem))
                }, null, 8, _hoisted_31))
              ]),
              "cell-product": _withCtx(({ row: productItem }) => [
                _createVNode(_component_product_info, {
                  gallery: _ctx.commonGalleryImages(productItem.imagesArray),
                  name: productItem.attribute.name,
                  name_en: productItem.attribute.name_en,
                  brand_id: productItem.__show.brand_name
                }, null, 8, ["gallery", "name", "name_en", "brand_id"])
              ]),
              "cell-price": _withCtx(({ row: productItem }) => [
                _createTextVNode(" €" + _toDisplayString(productItem.price.original_price), 1)
              ]),
              "cell-size": _withCtx(({ row: productItem }) => [
                (productItem.__show.size_name != '')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(productItem.__show.size_name), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_33, "--"))
              ]),
              "cell-material": _withCtx(({ row: productItem }) => [
                (productItem.attribute.material != '')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(productItem.attribute.material), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_35, "--"))
              ]),
              "cell-hsCode": _withCtx(({ row: productItem }) => [
                (
                    !(productItem.attribute.hs_code instanceof Array) &&
                    productItem.attribute.hs_code.DE != ''
                  )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(productItem.attribute.hs_code.DE), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_37, "--"))
              ]),
              "cell-country_of_origin": _withCtx(({ row: productItem }) => [
                (productItem.attribute.country_of_origin != '')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(productItem.attribute.country_of_origin) + " " + _toDisplayString(_ctx.getCountryLabel(productItem.attribute.country_of_origin)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_39, "--"))
              ]),
              "cell-sku_updated_at": _withCtx(({ row: productItem }) => [
                _createTextVNode(_toDisplayString(productItem.sku_updated_at
                    ? _ctx.formatDateTime(productItem.sku_updated_at)
                    : "--"), 1)
              ]),
              _: 1
            }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange"])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              type: "button",
              class: "btn btn-light",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
            }, [
              _createElementVNode("span", _hoisted_41, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.cancel")), 1)
            ])), [
              [_directive_auth, { auth: ['update'] }]
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitOneProduct()))
            }, [
              _createElementVNode("span", _hoisted_42, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr064.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.submit")), 1)
            ])
          ])
        ])
      ])
    ])
  ], 512))
}