export const dsSkuItemData: DsSKUListItem = {
  attachment_items: [],
  created_at: "",
  created_uid: 0,
  deleted_at: "",
  deleted_uid: 0,
  id: 0,
  is_exception: 0,
  item_exception: 0,
  item_id: 0,
  position: 0,
  remark: "",
  request_process_id: 0,
  sku_id: 0,
  sku_info: {
    attribute_set_id: 0,
    brand_article_no: "",
    brand_id: "",
    country_of_origin: "",
    extra_ean_codes: [],
    gallery: [],
    gtin: "",
    hs_code: {
      DE: "",
    },
    material: "",
    name: "",
    name_en: "",
    pos_article_no: "",
    price: {
      original_price: 0,
    },
    size: "",
    sku: "",
    sku_id: "",
    __show: {
      brand_name: "",
      size_name: "",
    },
    updated_at: "",
    updated_uid: 0,
  },
  sku_invoice_exception: 0,
};

export interface DsOrderListItem {
  created_at: string;
  created_uid: number;
  deleted_at: string;
  deleted_uid: number;
  id: number;
  merchant_id: number;
  merchant_name: string;
  order_id: number;
  package_number: string;
  relation_items: Array<any>;
  remark: string;
  sku_count: number;
  status: number;
  updated_at: string;
  updated_uid: number;
}

export interface DsSKUDetails {
  attachment_items: Array<AttachmentItem>;
  created_at: string;
  created_uid: number;
  deleted_at: string;
  deleted_uid: number;
  id: number;
  is_exception: number;
  merchant_id: number;
  merchant_name: string;
  notes: string;
  order_id: number;
  package_number: string;
  relation_items: Array<DsSKUListItem>;
  remark: string;
  status: number;
  updated_at: string;
  updated_uid: number;
}

export interface DsSKUListItem {
  attachment_items: Array<AttachmentItem>;
  created_at: string;
  created_uid: number;
  deleted_at: string;
  deleted_uid: number;
  id: number;
  is_exception: number;
  item_exception: number;
  item_id: number;
  position: number;
  remark: string;
  request_process_id: number;
  sku_id: number;
  sku_info: {
    attribute_set_id: number;
    brand_article_no: string;
    brand_id: string;
    country_of_origin: string;
    extra_ean_codes: Array<any>;
    gallery: ProductItemImage[];
    gtin: string;
    hs_code: {
      DE: string;
    };
    material: string;
    name: string;
    name_en: string;
    pos_article_no: string;
    price: {
      original_price: number;
    };
    size: string;
    sku: string;
    sku_id: string;
    __show: {
      brand_name: string;
      size_name: string;
    };
    updated_at: string;
    updated_uid: number;
  };
  sku_invoice_exception: number;
}

export interface AttachmentItem {
  created_at: string;
  created_uid: number;
  deleted_at: string;
  deleted_uid: number;
  file_id: number;
  file_name: string;
  file_url: string;
  id: number;
  is_exception: number;
  is_new?: number;
  notes: string;
  request_process_id: number;
  request_process_item_id: number;
  type: string;
  updated_at: string;
  updated_uid: number;
  rowErrorBorder?: boolean;
}

export interface CustomsInfo {
  hs_code: string;
  material: string;
  country_iso_2: string;
}

export interface SearchProducts {
  attribute: {
    adult: number;
    brand_article_no: string;
    brand_id: string;
    capacity: string;
    color: string;
    country_of_origin: string;
    custom_name: string;
    custom_number: string;
    description: string;
    gtin: string;
    height: string;
    length: string;
    material: string;
    max_energy_efficiency_class: string;
    merchant_article_no: string;
    min_energy_efficiency_class: string;
    mpn: string;
    name: string;
    name_de: string;
    name_en: string;
    net_weight: string;
    original_color: string;
    package_material: string;
    pattern: string;
    size: string;
    source_id: number;
    source_link: string;
    weight: string;
    width: string;
  };
  attribute_set_id: 0;
  categories: Array<any>;
  gallery: Array<Gallery>;
  id: string;
  imagesArray?: Array<string>;
  internal_type: number;
  merchant_id: number;
  price: { original_price: number; price: number; cn_official_price: number };
  product_identifier_id: number;
  spu_id: string;
  status: number;
  __show: {
    status_name: number;
    brand_name: string;
    size_name: string;
    color_name: string;
  };
}

export interface Gallery {
  is_primary: boolean;
  media_sign: string;
  media_url: string;
  position: number;
}

export interface ProductItemImage {
  id: number | string;
  origin_url: string;
}
