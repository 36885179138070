import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "symbol symbol-50px bg-transparent me-2"
}
const _hoisted_4 = {
  key: 1,
  class: "symbol symbol-50px overflow-hidden me-2"
}
const _hoisted_5 = { class: "symbol-label" }
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "flex-fill overflow-hidden d-flex flex-column justify-content-center" }
const _hoisted_8 = { class: "max-sm-text-nowrap" }
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 1,
  class: "fs-7 text-line-clamp-1 text-gray-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.gallery && _ctx.gallery.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_carousel, {
              class: "w-50px",
              "indicator-position": "none",
              height: "50px",
              autoplay: false,
              arrow: "never"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gallery, (row, rowIndex) => {
                  return (_openBlock(), _createBlock(_component_el_carousel_item, { key: rowIndex }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_image, {
                        class: "w-100 h-100 cursor-pointer",
                        src: row.origin_url + '?x-oss-process=image/resize,l_200',
                        fit: "contain",
                        onClick: _withModifiers(($event: any) => (_ctx.previewImages(_ctx.gallery, rowIndex)), ["prevent"])
                      }, null, 8, ["src", "onClick"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen006.svg" })
              ])
            ])
          ])),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_el_popover, {
          placement: "top",
          width: "auto",
          trigger: "hover"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.brand_id), 1),
              (_ctx.source_link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "fs-7 text-line-clamp-1 text-gray-400 text-hover-primary",
                    target: "_blank",
                    href: _ctx.source_link
                  }, _toDisplayString(_ctx.getName), 9, _hoisted_9))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.getName), 1))
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.getName), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}