
import { defineComponent, ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

export default defineComponent({
  name: "button-grop",
  props: {
    downloadButtonLoading: {
      type: Boolean,
      default: false,
    },
    reportButtonLoading: {
      type: Boolean,
      default: false,
    },
    saveButtonLoading: {
      type: Boolean,
      default: false,
    },
    publishButtonLoading: {
      type: Boolean,
      default: false,
    },
    saveButtonDisabled: {
      type: Boolean,
      default: false,
    },
    allButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    /*
     *下载按钮
     */
    const downloadButton = ref<HTMLElement | null>(null);
    watch(
      () => props.downloadButtonLoading,
      (newValue) => {
        if (downloadButton.value) {
          if (newValue == true) {
            downloadButton.value.setAttribute("data-kt-indicator", "on");
          } else {
            downloadButton.value.removeAttribute("data-kt-indicator");
          }
        }
      },
      { immediate: true }
    );
    const downloadButtonClick = () => {
      emit("downloadButtonClick");
    };

    /*
     *汇报按钮
     */
    const reportButton = ref<HTMLElement | null>(null);
    watch(
      () => props.reportButtonLoading,
      (newValue) => {
        if (reportButton.value) {
          if (newValue == true) {
            reportButton.value.setAttribute("data-kt-indicator", "on");
          } else {
            reportButton.value.removeAttribute("data-kt-indicator");
          }
        }
      },
      { immediate: true }
    );
    const reportButtonClick = () => {
      emit("reportButtonClick");
    };

    /*
     *保存按钮
     */
    const saveButton = ref<HTMLElement | null>(null);
    watch(
      () => props.saveButtonLoading,
      (newValue) => {
        if (saveButton.value) {
          if (newValue == true) {
            saveButton.value.setAttribute("data-kt-indicator", "on");
          } else {
            saveButton.value.removeAttribute("data-kt-indicator");
          }
        }
      },
      { immediate: true }
    );
    const saveButtonClick = () => {
      emit("saveButtonClick");
    };

    /*
     *发布按钮
     */
    const publishButton = ref<HTMLElement | null>(null);
    watch(
      () => props.publishButtonLoading,
      (newValue) => {
        if (publishButton.value) {
          if (newValue == true) {
            publishButton.value.setAttribute("data-kt-indicator", "on");
          } else {
            publishButton.value.removeAttribute("data-kt-indicator");
          }
        }
      },
      { immediate: true }
    );
    const publishButtonClick = () => {
      emit("publishButtonClick");
    };

    /*
     *响应式布局
     */
    const isMobile = ref(store.getters.isMobile);
    watch(
      () => store.getters.isMobile,
      (newValue) => {
        isMobile.value = newValue;
      }
    );

    onMounted(() => {
      if (downloadButton.value) {
        if (props.downloadButtonLoading == true) {
          downloadButton.value.setAttribute("data-kt-indicator", "on");
        } else {
          downloadButton.value.removeAttribute("data-kt-indicator");
        }
      }
      if (reportButton.value) {
        if (props.reportButtonLoading == true) {
          reportButton.value.setAttribute("data-kt-indicator", "on");
        } else {
          reportButton.value.removeAttribute("data-kt-indicator");
        }
      }
      if (saveButton.value) {
        if (props.saveButtonLoading == true) {
          saveButton.value.setAttribute("data-kt-indicator", "on");
        } else {
          saveButton.value.removeAttribute("data-kt-indicator");
        }
      }
      if (publishButton.value) {
        if (props.publishButtonLoading == true) {
          publishButton.value.setAttribute("data-kt-indicator", "on");
        } else {
          publishButton.value.removeAttribute("data-kt-indicator");
        }
      }
    });

    return {
      t,
      downloadButton,
      downloadButtonClick,
      reportButton,
      reportButtonClick,
      saveButton,
      saveButtonClick,
      publishButton,
      publishButtonClick,
      isMobile,
    };
  },
});
