
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { ApiDsSku } from "@/core/api";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import { CommonHtmlType } from "@/core/directive/type/common";
import { MBDatatable } from "magicbean-saas-common";
import { commonGalleryImages } from "@/core/directive/function/order";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import mixin from "@/mixins";
import { SearchProducts } from "@/core/directive/interface/dsSkuManagement";
import { TaggingItem } from "@/core/directive/interface/common";
import { formatDateTime } from "@/core/directive/function/common";

interface searchParams {
  item_id: number;
  brand: string;
  brand_id: string;
  brand_article_no: string;
  gtin: string;
}
interface SortOption {
  field: string;
  direction: string;
}
interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

interface Options {
  brand: TaggingItem[];
  brandLoading: boolean;
  size: TaggingItem[];
  sizeLoading: boolean;
  originCountry: TaggingItem[];
  originCountryLoading: boolean;
}

export default defineComponent({
  name: "quick-fill-tool",
  components: {
    MBDatatable,
    ProductInfo,
  },
  props: {
    searchParams: {
      type: Object as () => searchParams,
      default: () => {
        return { item_id: 0, brand: "", brand_article_no: "", gtin: "" };
      },
    },
    options: {
      type: Object as () => Options,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const SearchProductsRef = ref<CommonHtmlType>(null);
    const { showServerErrorMsg } = mixin();

    /*
     *拉取数据
     */
    const getQuickFillSearchData = async (
      brand_id: string,
      brand_article_no: string,
      gtin: string,
      page?: number,
      pageSize?: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiDsSku.getQuickFillSearchData({
        brand_id: brand_id,
        brand_article_no: brand_article_no,
        ean: gtin,
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            // 为表格提取图片数组
            for (let i = 0; i < data.data.items.length; i++) {
              data.data.items[i]["imagesArray"] = [];
              for (let j = 0; j < data.data.items[i].gallery.length; j++) {
                data.data.items[i]["imagesArray"].push(
                  data.data.items[i].gallery[j].media_url
                );
              }
            }
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          } else {
            setTimeout(() => {
              cancel();
            }, 400);
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *表格组件
     */
    const tableData = ref<Array<SearchProducts>>([]);
    const tableHeader = ref([
      { name: "", key: "radio", sortable: false },
      {
        name: t("products.productName"),
        key: "product",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.price"),
        key: "price",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.size"),
        key: "size",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.material"),
        key: "material",
        sortable: false,
      },
      {
        name: t("dsSkuManagement.hsCode"),
        key: "hsCode",
        sortable: false,
        align: "right",
      },
      {
        name: t("dsSkuManagement.countryOfOrigin"),
        key: "country_of_origin",
        sortable: false,
        align: "right",
      },
      {
        name: t("dsSkuManagement.updatedAt"),
        key: "sku_updated_at",
        sortable: false,
        align: "right",
      },
    ]);
    const loading = ref(true);
    const currentPage = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([]);
    const search = ref("");

    /*
     *获取原产国名称
     */
    const getCountryLabel = (value: string) => {
      const label = props.options?.originCountry.find((item) => {
        return item.value == value;
      })?.label;
      if (label) {
        return `(${label})`;
      } else {
        return "";
      }
    };

    /*
     *重置表格参数
     */
    const resetTable = () => {
      currentPage.value = 1;
      total.value = 0;
      pageSize.value = 10;
      filterOptions.value = [];
      sortOptions.value = [];
      search.value = "";
    };

    /*
     *分页功能
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getQuickFillSearchData(
        props.searchParams.brand_id,
        props.searchParams.brand_article_no,
        props.searchParams.gtin,
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getQuickFillSearchData(
        props.searchParams.brand_id,
        props.searchParams.brand_article_no,
        props.searchParams.gtin,
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    /*
     *传回所选数据
     */
    const chooseItem = ref<any>();
    const chooseChange = (item) => {
      chooseItem.value = item;
    };
    const chooseInit = () => {
      chooseItem.value = null;
    };
    const submitOneProduct = () => {
      const submitData = {
        item_id: props.searchParams.item_id,
        choose_item: chooseItem.value,
      };
      emit("submit", { data: submitData });
      hideModal(SearchProductsRef.value);
    };

    const cancel = () => {
      hideModal(SearchProductsRef.value);
    };

    onMounted(() => {
      modalShowListener(SearchProductsRef.value, () => {
        setTimeout(() => {
          getQuickFillSearchData(
            props.searchParams.brand_id,
            props.searchParams.brand_article_no,
            props.searchParams.gtin,
            currentPage.value,
            pageSize.value,
            search.value,
            sortOptions.value,
            filterOptions.value
          );
        });
      });
      modalHideListener(SearchProductsRef.value, () => {
        setTimeout(() => {
          tableData.value.splice(0, tableData.value.length);
          chooseInit();
          resetTable();
        });
      });
    });
    return {
      t,
      props,
      getCountryLabel,
      SearchProductsRef,
      tableData,
      tableHeader,
      loading,
      currentPage,
      total,
      pageSize,
      onCurrentPageChange,
      onRowsPerPageChange,
      commonGalleryImages,
      chooseChange,
      submitOneProduct,
      cancel,
      chooseItem,
      formatDateTime,
    };
  },
});
