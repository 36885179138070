/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-11-22 09:58:12
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-11-22 10:14:54
 * @FilePath: /pim-frontend/src/core/directive/function/download.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const downloadUrl = (data, filename: string, mime?: string, bom?) => {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || "text/plain" });
  const navigator = window.navigator as any;
  if (typeof navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    navigator.msSaveBlob(blob, filename);
  } else {
    // const blobURL =
    //   window.URL && window.URL.createObjectURL
    //     ? window.URL.createObjectURL(blob)
    //     : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    // tempLink.href = blobURL;
    tempLink.href = data;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      // window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};
