
import {
  defineComponent,
  onMounted,
  ref,
  getCurrentInstance,
  watch,
} from "vue";
import {
  DsSKUListItem,
  dsSkuItemData,
} from "@/core/directive/interface/dsSkuManagement";
import { TaggingItem } from "@/core/directive/interface/common";
import { useI18n } from "vue-i18n";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import _ from "lodash";
import { removeReadonly } from "@/core/directive/function/common";
import { ApiBase, ApiDsSku } from "@/core/api";
import WindowResize from "@/components/layout/WindowResize.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getSkuProductTemplate,
  isNoSizeSku,
} from "@/core/directive/function/dsSkuManagement";
import { ProductTemplate } from "@/core/directive/type/dsSkuManagement";

interface Options {
  brand: TaggingItem[];
  brandLoading: boolean;
  size: TaggingItem[];
  sizeLoading: boolean;
  originCountry: TaggingItem[];
  originCountryLoading: boolean;
  productTemplate: TaggingItem[];
  productTemplateLoading: boolean;
}

export default defineComponent({
  name: "single-SKU-card",
  props: {
    itemData: {
      type: Object as () => DsSKUListItem,
      default: () => dsSkuItemData,
    },
    options: {
      type: Object as () => Options,
    },
    status: {
      type: Number,
      default: 0,
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WindowResize,
    ProductInfo,
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    /*
     *必填项验证
     */
    const rules = ref({
      brand_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      brand_article_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      price: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (value <= 0) {
              callback(new Error(t("dsSkuManagement.PriceMustBeGreaterThan0")));
            } else {
              callback();
            }
          },
          trigger: ["blur"],
        },
      ],
      size_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });
    //建一个平级的对象用于el-form验证必填项
    const validModel = ref({
      brand_id: "",
      brand_article_no: "",
      price: 0,
      size_name: "",
      name: "",
    });
    const validModelInit = () => {
      validModel.value.brand_id = thisItem.value.sku_info.brand_id;
      validModel.value.brand_article_no =
        thisItem.value.sku_info.brand_article_no;
      validModel.value.price = thisItem.value.sku_info.price.original_price;
      validModel.value.size_name = thisItem.value.sku_info.__show.size_name;
      validModel.value.name = thisItem.value.sku_info.name;
    };

    /*
     *不断地将数据变化传递给父组件
     */
    const thisItem = ref<DsSKUListItem>(dsSkuItemData);
    const thisItemInit = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    watch(
      () => thisItem,
      () => {
        validModel.value.brand_id = thisItem.value.sku_info.brand_id;
        validModel.value.brand_article_no =
          thisItem.value.sku_info.brand_article_no;
        validModel.value.price = thisItem.value.sku_info.price.original_price;
        validModel.value.size_name = thisItem.value.sku_info.__show.size_name;
        validModel.value.name = thisItem.value.sku_info.name;

        if (thisItemInit.value) {
          emit("formDataChange", { data: thisItem.value });
        }
      },
      { deep: true }
    );

    /*
     *由父组件控制本行是否进行验证
     */
    watch(
      () => props.valid,
      (newValue) => {
        if (newValue) {
          if (formRef.value) {
            formRef.value.validate((valid) => {
              if (valid) {
                emit("pass");
              } else {
                emit("noPass");
              }
            });
          }
        }
      },
      { deep: true }
    );

    /*
     *自动填充英文名称
     */
    const autofillNameEn = () => {
      thisItem.value.sku_info.name_en = thisItem.value.sku_info.name;
    };

    /*
     *下拉框配置
     */
    const thisOptions = ref({
      brand: [] as TaggingItem[],
      brandLoading: false,
      size: [] as TaggingItem[],
      sizeLoading: false,
      originCountry: [] as TaggingItem[],
      originCountryLoading: false,
      productTemplate: [] as TaggingItem[],
      productTemplateLoading: false,
    });
    const currentInstance: any = getCurrentInstance();
    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    /*
     *下拉选择品牌名称
     */
    const getBrandSourceData = async (value) => {
      thisOptions.value.brandLoading = true;
      let params = {
        search_value: value,
      };
      const { data } = await ApiBase.getBrandSourceData(params);
      thisOptions.value.brandLoading = false;
      if (data.code == 0) {
        thisOptions.value.brand = data.data;
      }
    };
    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);
    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    /*
     *下拉选择尺寸
     */
    const getSizeSourceData = async (value) => {
      thisOptions.value.sizeLoading = true;
      let attribute_set_id = 1;
      if (value.attribute_set_id != undefined) {
        attribute_set_id = value.attribute_set_id;
      }
      let params = {
        attribute_set_id: attribute_set_id,
        search_value: value.search_value,
      };
      const { data } = await ApiDsSku.getSizeSourceData(params);

      thisOptions.value.sizeLoading = false;
      if (data.code == 0) {
        thisOptions.value.size = data.data;
      }
    };
    const debounceSizeSourceSearch = _.debounce(getSizeSourceData, 1000);
    const searchSizeSourceItems = (query: string, attribute_set_id: number) => {
      if (query != "") {
        const obj = {
          search_value: query,
          attribute_set_id: attribute_set_id,
        };
        debounceSizeSourceSearch(obj);
      }
    };
    // 在input事件中临时存储size输入框里面的内容，绕开blur事件的e.target丢失的问题
    const sizeInputTemp = ref<string>("");
    const sizeSelectInput = (e) => {
      sizeInputTemp.value = e.target.value;
    };
    // 可自定义输入，回显size，并填充__show.brand_name
    const sizeSelectBlur = () => {
      thisItem.value.sku_info.__show.size_name = sizeInputTemp.value;
      if (
        !thisOptions.value.size.find((item) => {
          return item.value == sizeInputTemp.value;
        })
      ) {
        thisItem.value.sku_info.size = sizeInputTemp.value;
      }
      sizeInputTemp.value = "";
    };
    // 可选择，自动填充__show.size_name
    const sizeSelectChange = (event) => {
      const label = thisOptions.value.size.find((item) => {
        return item.value == event;
      })?.label;
      if (label) {
        thisItem.value.sku_info.__show.size_name = label;
      } else {
        thisItem.value.sku_info.__show.size_name = "";
      }
    };

    /*
     *根据下拉选择的brand_id，自动填充__show.brand_name
     */
    const autofillBrandName = (event) => {
      if (thisItem.value.sku_info.brand_id != "") {
        const array = thisOptions.value.brand;
        const result: any = array.find(function (item) {
          return item.value == event;
        }, array);
        thisItem.value.sku_info.__show.brand_name = result.label;
      } else {
        thisItem.value.sku_info.__show.brand_name = "";
      }
    };

    /*
     *下拉选择商品类目
     */
    const getproductTemplateSourceData = () => {
      thisOptions.value.productTemplate = [
        {
          value: ProductTemplate.Clothing,
          label: getSkuProductTemplate(ProductTemplate.Clothing),
        },
        {
          value: ProductTemplate.BagSuitcase,
          label: getSkuProductTemplate(ProductTemplate.BagSuitcase),
        },
        {
          value: ProductTemplate.Jewelry,
          label: getSkuProductTemplate(ProductTemplate.Jewelry),
        },
        {
          value: ProductTemplate.Footwear,
          label: getSkuProductTemplate(ProductTemplate.Footwear),
        },
        {
          value: ProductTemplate.Other,
          label: getSkuProductTemplate(ProductTemplate.Other),
        },
      ];
    };

    /*
     *汇报按钮
     */
    const reportButtonClick = () => {
      emit("reportButtonClick", { data: thisItem.value });
    };

    /*
     *搜索按钮
     */
    const notEnoughTips = () => {
      Swal.fire({
        text: t("dsSkuManagement.notEnoughSearchParam"),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("common.okGotIt"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };
    const searchButtonClick = () => {
      if (
        thisItem.value.sku_info.brand_id == "" &&
        thisItem.value.sku_info.brand_article_no == "" &&
        thisItem.value.sku_info.gtin &&
        ""
      ) {
        return;
      } else {
        emit("searchButtonClick", { data: thisItem.value });
      }
    };

    onMounted(() => {
      thisItem.value = _.cloneDeep(props.itemData);
      thisOptions.value = _.cloneDeep(props.options) as Options;
      setTimeout(() => {
        thisItemInit.value = true;
        validModelInit();
        getproductTemplateSourceData();
      });
    });

    return {
      t,
      isNoSizeSku,
      rules,
      formRef,
      validModel,
      thisItem,
      thisOptions,
      cancelReadonly,
      searchBrandSourceItems,
      searchSizeSourceItems,
      sizeSelectInput,
      sizeSelectBlur,
      sizeSelectChange,
      autofillNameEn,
      autofillBrandName,
      reportButtonClick,
      searchButtonClick,
      notEnoughTips,
    };
  },
});
