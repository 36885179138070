
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener } from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import { commonGalleryImages } from "@/core/directive/function/order";
import mixin from "@/mixins";
import { ApiDsSku } from "@/core/api";
import _ from "lodash";
import { AttachmentItem } from "@/core/directive/interface/dsSkuManagement";

interface exceptionProductParams {
  id: number;
  item_id: number;
  brand: string;
  brand_article_no: string;
  gtin: string;
  attachment_items: Array<AttachmentItem>;
  is_exception: number;
  sku_invoice_exception: number;
}

export default defineComponent({
  name: "report-product-exception",
  components: {
    ProductInfo,
  },
  props: {
    exceptionData: {
      type: Object as () => exceptionProductParams,
      default: () => {
        return {
          id: 0,
          item_id: 0,
          brand: "",
          brand_article_no: "",
          gtin: "",
          attachment_items: [],
          is_exception: 20,
          sku_invoice_exception: 20,
        };
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const reportProductExceptionRef = ref<CommonHtmlType>(null);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const loading = ref<boolean>(false);

    /*
     *拉取数据
     */
    const formData = ref<exceptionProductParams>();
    const getFormData = () => {
      formData.value = _.cloneDeep(props.exceptionData);
      for (
        let index = 0;
        index < formData.value.attachment_items.length;
        index++
      ) {
        formData.value.attachment_items[index].rowErrorBorder = false;
      }
    };

    /*
     *勾选交互
     */
    const productNotFoundChange = () => {
      if (formData.value?.is_exception == 20) {
        formData.value.is_exception = 10;
      } else if (formData.value?.is_exception == 10) {
        formData.value.is_exception = 20;
      }
    };
    const invoiceMissingChange = () => {
      if (formData.value?.sku_invoice_exception == 20) {
        formData.value.sku_invoice_exception = 10;
      } else if (formData.value?.sku_invoice_exception == 10) {
        formData.value.sku_invoice_exception = 20;
      }
    };
    const formCheckboxChange = (index: number) => {
      if (formData.value?.attachment_items[index].is_exception == 20) {
        formData.value.attachment_items[index].is_exception = 10;
      } else if (formData.value?.attachment_items[index].is_exception == 10) {
        formData.value.attachment_items[index].is_exception = 20;
      }
    };

    /*
     *提交
     */
    const submit = () => {
      loading.value = true;
      const items: any = [];
      const item_msg: any = [];
      if (
        formData.value?.is_exception == 10 &&
        props.exceptionData.is_exception == 20
      ) {
        // 新增商品未找到异常
        item_msg.push({
          item_id: props.exceptionData.item_id,
          status: "10",
          type: "product_item",
        });
      }
      if (
        formData.value?.is_exception == 20 &&
        props.exceptionData.is_exception == 10
      ) {
        // 取消商品未找到异常
        item_msg.push({
          item_id: formData.value.item_id,
          status: "20",
          type: "product_item",
        });
      }
      if (
        formData.value?.sku_invoice_exception == 10 &&
        props.exceptionData.sku_invoice_exception == 20
      ) {
        // 新增发票缺失异常
        item_msg.push({
          item_id: props.exceptionData.item_id,
          status: "10",
          type: "missing_invoice_sku",
        });
      }
      if (
        formData.value?.sku_invoice_exception == 20 &&
        props.exceptionData.sku_invoice_exception == 10
      ) {
        // 取消发票缺失异常
        item_msg.push({
          item_id: formData.value.item_id,
          status: "20",
          type: "missing_invoice_sku",
        });
      }
      let errorFlag = false;
      if (formData.value?.attachment_items.length) {
        for (
          let index = 0;
          index < formData.value.attachment_items.length;
          index++
        ) {
          if (
            formData.value.attachment_items[index].is_exception == 10 &&
            props.exceptionData.attachment_items[index].is_exception == 20
          ) {
            // 新增图片异常
            // console.log(
            //   "新增图片异常",
            //   formData.value.attachment_items[index].type
            // );
            if (formData.value.attachment_items[index].notes != "") {
              items.push({
                item_id: formData.value.item_id,
                status: "10",
                file_id: formData.value.attachment_items[index].file_id,
                type: formData.value.attachment_items[index].type,
                notes: formData.value.attachment_items[index].notes,
              });
            } else {
              formData.value.attachment_items[index].rowErrorBorder = true;
              showValidateErrorMsg();
              errorFlag = true;
            }
          }
          if (
            formData.value.attachment_items[index].is_exception == 10 &&
            props.exceptionData.attachment_items[index].is_exception == 10
          ) {
            if (formData.value.attachment_items[index].notes != "") {
              if (
                formData.value.attachment_items[index].notes !=
                props.exceptionData.attachment_items[index].notes
              ) {
                // 修改图片异常
                // console.log(
                //   "修改图片异常",
                //   formData.value.attachment_items[index].type
                // );
                items.push({
                  item_id: formData.value.item_id,
                  status: "10",
                  file_id: formData.value.attachment_items[index].file_id,
                  type: formData.value.attachment_items[index].type,
                  notes: formData.value.attachment_items[index].notes,
                });
              }
            } else {
              formData.value.attachment_items[index].rowErrorBorder = true;
              showValidateErrorMsg();
              errorFlag = true;
            }
          }
          if (
            formData.value.attachment_items[index].is_exception == 20 &&
            props.exceptionData.attachment_items[index].is_exception == 10
          ) {
            // 取消图片异常
            // console.log(
            //   "取消图片异常",
            //   formData.value.attachment_items[index].type
            // );
            items.push({
              item_id: formData.value.item_id,
              status: "20",
              file_id: formData.value.attachment_items[index].file_id,
              type: formData.value.attachment_items[index].type,
              notes: "",
            });
          }
        }
      }
      if (errorFlag == true) {
        loading.value = false;
        return;
      } else {
        let submitData = {
          id: props.exceptionData.id,
        };
        if (item_msg.length > 0) {
          submitData["item_msg"] = item_msg;
        }
        if (items.length > 0) {
          submitData["items"] = items;
        }
        ApiDsSku.reportException(submitData)
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              cancel();
              emit("submit");
              showFormSubmitSuccessMsg(() => {
                return;
              });
            } else {
              showServerErrorMsg(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    /*
     *关闭弹框
     */
    const cancel = () => {
      hideModal(reportProductExceptionRef.value);
    };
    const resetForm = () => {
      formData.value = {
        id: 0,
        item_id: 0,
        brand: "",
        brand_article_no: "",
        gtin: "",
        attachment_items: [],
        is_exception: 20,
        sku_invoice_exception: 20,
      };
    };
    onMounted(() => {
      modalShowListener(reportProductExceptionRef.value, () => {
        setTimeout(() => {
          getFormData();
        });
      });
      modalHideListener(reportProductExceptionRef.value, () => {
        setTimeout(() => {
          resetForm();
        });
      });
    });

    return {
      t,
      props,
      loading,
      reportProductExceptionRef,
      formData,
      commonGalleryImages,
      cancel,
      submit,
      productNotFoundChange,
      invoiceMissingChange,
      formCheckboxChange,
    };
  },
});
