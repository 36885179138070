import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap justify-content-end gap-4" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "indicator-label" }
const _hoisted_4 = { class: "indicator-progress" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "indicator-label" }
const _hoisted_7 = { class: "indicator-progress" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "indicator-label" }
const _hoisted_10 = { class: "indicator-progress" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "indicator-label" }
const _hoisted_13 = { class: "indicator-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      ref: "downloadButton",
      class: "btn btn-light-primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadButtonClick())),
      disabled: _ctx.downloadButtonLoading || _ctx.allButtonDisabled
    }, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["svg-icon svg-icon-2", {
            'm-0': _ctx.isMobile,
          }])
        }, [
          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/files/fil017.svg" })
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("dsSkuManagement.downloadSkuImages")), 3)
      ]),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass(["spinner-border spinner-border-sm align-middle", {
            'me-2': !_ctx.isMobile,
            'm-0': _ctx.isMobile,
          }])
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(_ctx.t("common.pleaseWait")), 1)
      ])
    ], 8, _hoisted_2)), [
      [_directive_auth, { auth: ['export'] }]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      ref: "saveButton",
      class: "btn btn-secondary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveButtonClick())),
      disabled: _ctx.saveButtonLoading || _ctx.saveButtonDisabled || _ctx.allButtonDisabled
    }, [
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass(["svg-icon svg-icon-2", {
            'm-0': _ctx.isMobile,
          }])
        }, [
          _createVNode(_component_inline_svg_icon, { src: "media/svg/custom/submit.svg" })
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("dsSkuManagement.save")), 3)
      ]),
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("span", {
          class: _normalizeClass(["spinner-border spinner-border-sm align-middle", {
            'me-2': !_ctx.isMobile,
            'm-0': _ctx.isMobile,
          }])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("common.pleaseWait")), 3)
      ])
    ], 8, _hoisted_5)), [
      [_directive_auth, { auth: ['update'] }]
    ]),
    _createElementVNode("button", {
      type: "button",
      ref: "reportButton",
      class: "btn btn-danger",
      "data-bs-toggle": "modal",
      "data-bs-target": "#modal_report_invoice_exception",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reportButtonClick())),
      disabled: _ctx.reportButtonLoading || _ctx.allButtonDisabled
    }, [
      _createElementVNode("span", _hoisted_9, [
        _createElementVNode("span", {
          class: _normalizeClass(["svg-icon svg-icon-2", {
            'm-0': _ctx.isMobile,
          }])
        }, [
          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen044.svg" })
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("dsSkuManagement.reportInvoiceException")), 3)
      ]),
      _createElementVNode("span", _hoisted_10, [
        _createElementVNode("span", {
          class: _normalizeClass(["spinner-border spinner-border-sm align-middle", {
            'me-2': !_ctx.isMobile,
            'm-0': _ctx.isMobile,
          }])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("common.pleaseWait")), 3)
      ])
    ], 8, _hoisted_8),
    _withDirectives((_openBlock(), _createElementBlock("button", {
      type: "button",
      ref: "publishButton",
      class: "btn btn-primary",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.publishButtonClick())),
      disabled: _ctx.publishButtonLoading || _ctx.allButtonDisabled
    }, [
      _createElementVNode("span", _hoisted_12, [
        _createElementVNode("span", {
          class: _normalizeClass(["svg-icon svg-icon-2", {
            'm-0': _ctx.isMobile,
          }])
        }, [
          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen043.svg" })
        ], 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("dsSkuManagement.publish")), 3)
      ]),
      _createElementVNode("span", _hoisted_13, [
        _createElementVNode("span", {
          class: _normalizeClass(["spinner-border spinner-border-sm align-middle", {
            'me-2': !_ctx.isMobile,
            'm-0': _ctx.isMobile,
          }])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass({
            'd-none': _ctx.isMobile,
          })
        }, _toDisplayString(_ctx.t("common.pleaseWait")), 3)
      ])
    ], 8, _hoisted_11)), [
      [_directive_auth, { auth: ['update'] }]
    ])
  ]))
}