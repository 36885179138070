
import { defineComponent, ref, onMounted, watch } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiDsSku } from "@/core/api";
import { useI18n } from "vue-i18n";
import { commonGalleryImages } from "@/core/directive/function/order";
import _ from "lodash";
import md5 from "js-md5";
import { formatDate } from "@/core/directive/function/common";
import mixin from "@/mixins";
import { useRoute, useRouter } from "vue-router";
import { setModuleBCN } from "@/core/directive/function/common";
import ButtonGrop from "./ButtonGrop.vue";
import SingleSKUCard from "./SingleSKUCard.vue";
import {
  DsSKUListItem,
  DsSKUDetails,
  AttachmentItem,
} from "@/core/directive/interface/dsSkuManagement";
import { TaggingItem } from "@/core/directive/interface/common";
import WindowResize from "@/components/layout/WindowResize.vue";
import ReportInvoiceException from "./ReportInvoiceException.vue";
import ReportProductException from "./ReportProductException.vue";
import SearchProducts from "./SearchProducts.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "@/store";
import { downloadUrl } from "@/core/directive/function/download";
import { computed } from "vue";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getDsOrderStatusClass,
  getDsOrderStatusText,
  isNoSizeSku,
} from "@/core/directive/function/dsSkuManagement";

interface exceptionProductParams {
  id: number;
  item_id: number;
  brand: string;
  brand_article_no: string;
  gtin: string;
  attachment_items: Array<AttachmentItem>;
  is_exception: number;
  sku_invoice_exception: number;
}
interface exceptionInvoiceParams {
  id: number;
  attachment_items: Array<AttachmentItem>;
  is_exception: number;
  notes: string;
}
interface searchParams {
  item_id: number;
  brand: string;
  brand_id: string;
  brand_article_no: string;
  gtin: string;
}

export default defineComponent({
  name: "products-ds-sku-management-information",
  components: {
    WindowResize,
    ButtonGrop,
    SingleSKUCard,
    ReportInvoiceException,
    ReportProductException,
    SearchProducts,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    /*
     *卡片组件刷新标志(通过以下方法刷新组件，是Vue的组件更新，不会重新拉取数据，不会带Loading样式，用于父组件数据更改后同步子组件UI)
     */
    const refushAllCardFlag = ref<number>(0);
    const refushSingleCardFlag = ref<Array<number>>([]);
    // 刷新所有卡片组件
    const refushAllCardFlagChange = () => {
      refushAllCardFlag.value = new Date().getTime();
    };
    // 刷新单个卡片组件
    const refushSingleCardFlagChange = (indexArray: Array<number>) => {
      for (let i = 0; i < refushSingleCardFlag.value.length; i++) {
        for (let j = 0; j < indexArray.length; j++) {
          if (i == indexArray[j]) {
            refushSingleCardFlag.value.splice(i, 1, new Date().getTime());
          }
        }
      }
    };

    /*
     *拉取数据
     */
    const detailsLoading = ref(true);
    const mainCardLoading = ref(true);
    const SKUCardLoading = ref<Array<boolean>>([]); // 分卡片的Loading效果标记
    const hasSKUCardLoading = () => {
      const flag = SKUCardLoading.value.some((item) => {
        return item == true;
      });
      return flag;
    };

    const _thisId = ref("");
    const detailsData = ref<DsSKUDetails>();
    const formData = ref<Array<DsSKUListItem>>([]);

    const rawData = ref<DsSKUDetails>();
    const status = ref<number>(0);
    const lengthIsInit = ref(false);

    /**
     * @description: 向页面上渲染SKU数据的方法
     * @param id:向API发送的查询参数（订单总ID）
     * @param range:页面刷新类型：全部、单行、只刷新异常
     * @param indexArray:可选，需要刷新的index数组，如果是刷新单行，或只刷新异常，需要传入该参数
     * @return {*}
     */
    const getDsSkuDetails = (
      id: string,
      range: "all" | "single" | "exception",
      indexArray?: Array<number>
    ) => {
      _thisId.value = id;
      // 显示Loading效果
      if (range == "all") {
        detailsLoading.value = true;
        if (formData.value.length == 0) {
          mainCardLoading.value = true;
        }
        if (lengthIsInit.value) {
          for (let i = 0; i < SKUCardLoading.value.length; i++) {
            SKUCardLoading.value.splice(i, 1, true);
          }
        }
      } else {
        if (indexArray) {
          for (let i = 0; i < formData.value.length; i++) {
            for (let j = 0; j < indexArray.length; j++) {
              if (i == indexArray[j]) {
                SKUCardLoading.value.splice(i, 1, true);
              }
            }
          }
        }
      }
      ApiDsSku.getDsSkuDetails({
        id: id,
      })
        .then(({ data }) => {
          // 取消Loading效果
          if (range == "all") {
            detailsLoading.value = false;
            if (formData.value.length == 0) {
              mainCardLoading.value = false;
            }
            if (lengthIsInit.value) {
              for (let i = 0; i < SKUCardLoading.value.length; i++) {
                SKUCardLoading.value.splice(i, 1, false);
              }
            }
          } else {
            if (indexArray) {
              for (let i = 0; i < formData.value.length; i++) {
                for (let j = 0; j < indexArray.length; j++) {
                  if (i == indexArray[j]) {
                    SKUCardLoading.value.splice(i, 1, false);
                  }
                }
              }
            }
          }

          // 处理返回信息
          if (data.code == 0) {
            const dataLength = data.data.relation_items.length;

            // 获取到真实长度后，初始化Loading效果和子组件刷新标志
            if (!lengthIsInit.value) {
              for (let i = 0; i < dataLength; i++) {
                SKUCardLoading.value.push(false);
                refushSingleCardFlag.value.push(new Date().getTime());
              }
              lengthIsInit.value = true;
            }

            // 处理hs_code字段为空数组情况
            for (let i = 0; i < dataLength; i++) {
              if (
                data.data.relation_items[i].sku_info.hs_code instanceof Array
              ) {
                data.data.relation_items[i].sku_info.hs_code = {
                  DE: "",
                };
              }
            }

            //为表格下拉选择框初始化回显
            for (let i = 0; i < dataLength; i++) {
              if (data.data.relation_items[i].sku_info.brand_id != "") {
                if (
                  !options.value.brand.find((item) => {
                    return (
                      item.value ==
                      data.data.relation_items[i].sku_info.brand_id
                    );
                  })
                ) {
                  options.value.brand.push({
                    label:
                      data.data.relation_items[i].sku_info.__show.brand_name,
                    value: data.data.relation_items[i].sku_info.brand_id,
                  });
                }
              }
              if (data.data.relation_items[i].sku_info.size != "") {
                if (
                  !options.value.size.find((item) => {
                    return (
                      item.value == data.data.relation_items[i].sku_info.size
                    );
                  })
                ) {
                  if (
                    data.data.relation_items[i].sku_info.__show.size_name != ""
                  ) {
                    options.value.size.push({
                      label:
                        data.data.relation_items[i].sku_info.__show.size_name,
                      value: data.data.relation_items[i].sku_info.size,
                    });
                  } else {
                    data.data.relation_items[i].sku_info.size = "";
                  }
                }
              }
            }
            //为表格设置size为default的回显
            for (let i = 0; i < dataLength; i++) {
              if (isNoSizeSku(data.data.relation_items[i])) {
                data.data.relation_items[i].sku_info.__show.size_name =
                  "default";
              }
            }
            // 备份原始数据，用于对比本地和远程数据是否有改变
            rawData.value = _.cloneDeep(data.data);
            // 页面显示数据赋值
            status.value = data.data.status;
            detailsData.value = data.data as DsSKUDetails; // detailsData只用于表头信息显示，不涉及SKU卡片
            if (range == "all") {
              //更新全部
              formData.value.splice(
                0,
                formData.value.length,
                ...data.data.relation_items
              );
              refushAllCardFlagChange(); // 刷新Vue子组件
            } else if (range == "single") {
              //更新单行
              if (indexArray) {
                for (let i = 0; i < dataLength; i++) {
                  for (let j = 0; j < indexArray.length; j++) {
                    if (i == indexArray[j]) {
                      formData.value.splice(i, 1, data.data.relation_items[i]);
                    }
                  }
                }
                refushSingleCardFlagChange(indexArray); // 刷新Vue子组件
              }
            } else if (range == "exception") {
              //只更新产品异常
              if (indexArray) {
                for (let i = 0; i < dataLength; i++) {
                  for (let j = 0; j < indexArray.length; j++) {
                    if (i == indexArray[j]) {
                      formData.value[i].item_exception =
                        data.data.relation_items[i].item_exception;
                      formData.value[i].is_exception =
                        data.data.relation_items[i].is_exception;
                      formData.value[i].sku_invoice_exception =
                        data.data.relation_items[i].sku_invoice_exception;
                      formData.value[i].attachment_items.splice(
                        0,
                        formData.value[i].attachment_items.length,
                        ...data.data.relation_items[i].attachment_items
                      );
                    }
                  }
                }
              }
            }
            // 初始化：必填验证
            itemPassedInit();
            // 初始化：是否有变化
            itemChangeInit();
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *状态显示
     */
    const dsSkuStatusClass = computed(() => {
      return (status: NumberOrString) => {
        return getDsOrderStatusClass(status);
      };
    });
    const dsSkuStatusText = computed(() => {
      return (status: NumberOrString) => {
        return getDsOrderStatusText(status);
      };
    });

    /*
     *刷新数据的方法(通过以下方法刷新组件，是重新从API拉取数据，回显到页面上，带Loading样式，一般用这种方式彻底刷新)
     */
    // 刷新所有数据
    const refushAllData = () => {
      getDsSkuDetails(_thisId.value, "all");
    };
    // 刷新单行数据
    const refushSingleData = (indexArray: Array<number>) => {
      getDsSkuDetails(_thisId.value, "single", indexArray);
    };
    // 刷新产品异常
    const refushItemExceptionData = (item_id: number) => {
      for (let i = 0; i < formData.value.length; i++) {
        if (formData.value[i].item_id == item_id) {
          getDsSkuDetails(_thisId.value, "exception", [i]);
        }
      }
    };

    /*
     *接收卡片组件数据变化
     */
    const formDataChange = (val: any) => {
      const thisItem = val.data;
      for (let i = 0; i < formData.value.length; i++) {
        if (formData.value[i].item_id == thisItem.item_id) {
          formData.value.splice(i, 1, thisItem);
        }
      }
    };

    /*
     *必填项验证
     */
    const itemValid = ref<Array<boolean>>([]);
    const itemPassed = ref<Array<boolean>>([]);
    const itemPassedInit = () => {
      itemPassed.value.splice(0, itemPassed.value.length);
      itemValid.value.splice(0, itemValid.value.length);
      for (let i = 0; i < formData.value.length; i++) {
        itemPassed.value.push(false);
        itemValid.value.push(false);
      }
    };
    const itemPass = (index: number) => {
      itemPassedChange(index, true);
    };
    const itemNoPass = (index: number) => {
      itemPassedChange(index, false);
    };
    const itemPassedChange = (index: number, status: boolean) => {
      if (status) {
        itemPassed.value.splice(index, 1, true);
      } else {
        itemPassed.value.splice(index, 1, false);
      }
    };

    /*
     *下拉框配置
     */
    const options = ref({
      brand: [] as TaggingItem[],
      brandLoading: false,
      size: [] as TaggingItem[],
      sizeLoading: false,
      originCountry: [] as TaggingItem[],
      originCountryLoading: false,
      productTemplate: [] as TaggingItem[],
      productTemplateLoading: false,
    });

    /*
     *下拉选择国家
     */
    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.originCountry = data.data;
      }
      getDsSkuDetails(route.params.id as string, "all");
    };

    /*
     *下载SKU图片包
     */
    const downloadButtonLoading = ref(false);
    const downloadSkuImages = async () => {
      if (detailsData.value) {
        downloadButtonLoading.value = true;
        const submitId = detailsData.value.id;
        const { data } = await ApiDsSku.downloadSkuImages({
          id: submitId,
        });
        downloadButtonLoading.value = false;
        if (data.code === 0) {
          const zip_url = data.data.zip_url;
          const start = zip_url.lastIndexOf("/"),
            end = data.data.zip_url.indexOf("?");
          const name = zip_url.substring(start + 1, end);
          downloadUrl(zip_url, name, "application/zip");
        } else {
          showServerErrorMsg(data);
        }
      }
    };

    /*
     *传递发票异常数据
     */
    const reportInvoiceButtonLoading = ref(false);
    const exceptionInvoiceParams = ref<exceptionInvoiceParams>({
      id: 0,
      attachment_items: [],
      is_exception: 20,
      notes: "",
    });
    const exceptionInvoiceDataChange = () => {
      exceptionInvoiceParams.value.id = detailsData.value?.id as number;
      exceptionInvoiceParams.value.attachment_items = detailsData.value
        ?.attachment_items as Array<AttachmentItem>;
      exceptionInvoiceParams.value.is_exception = detailsData.value
        ?.is_exception as number;
      exceptionInvoiceParams.value.notes = detailsData.value?.notes as string;
      reportInvoiceButtonLoadingChange(true);
    };
    const reportInvoiceButtonLoadingChange = (status: boolean) => {
      reportInvoiceButtonLoading.value = status;
    };

    /*
     *传递商品异常数据
     */
    const exceptionProductParams = ref<exceptionProductParams>({
      id: 0,
      item_id: 0,
      brand: "",
      brand_article_no: "",
      gtin: "",
      attachment_items: [],
      is_exception: 20,
      sku_invoice_exception: 20,
    });
    const exceptionProductDataChange = (val: any) => {
      const thisItem = val.data;
      exceptionProductParams.value.id = detailsData.value?.id as number;
      exceptionProductParams.value.item_id = thisItem.item_id;
      exceptionProductParams.value.brand = thisItem.sku_info.__show.brand_name;
      exceptionProductParams.value.brand_article_no =
        thisItem.sku_info.brand_article_no;
      exceptionProductParams.value.gtin = thisItem.sku_info.gtin;
      exceptionProductParams.value.is_exception = thisItem.is_exception;
      exceptionProductParams.value.sku_invoice_exception =
        thisItem.sku_invoice_exception;
      exceptionProductParams.value.attachment_items =
        thisItem.attachment_items as Array<AttachmentItem>;
    };

    /*
     *自动填充工具
     */
    const searchParams = ref<searchParams>({
      item_id: 0,
      brand: "",
      brand_id: "",
      brand_article_no: "",
      gtin: "",
    });
    const searchDataChange = (val: any) => {
      const thisItem = val.data;
      searchParams.value.item_id = thisItem.item_id;
      searchParams.value.brand = thisItem.sku_info.__show.brand_name;
      searchParams.value.brand_id = thisItem.sku_info.brand_id;
      searchParams.value.brand_article_no = thisItem.sku_info.brand_article_no;
      searchParams.value.gtin = thisItem.sku_info.gtin;
    };
    const chooseProduct = (val: any) => {
      const item_id = val.data.item_id;
      const chooseItem = val.data.choose_item;

      // 处理hs_code字段为空数组情况
      if (chooseItem.attribute.hs_code instanceof Array) {
        chooseItem.attribute.hs_code = {
          DE: "",
        };
      }

      //为表格下拉选择框初始化回显
      if (chooseItem.attribute.brand_id != "") {
        if (
          !options.value.brand.find((item) => {
            return item.value == chooseItem.attribute.brand_id;
          })
        ) {
          options.value.brand.push({
            label: chooseItem.__show.brand_name,
            value: chooseItem.attribute.brand_id,
          });
        }
      }
      if (chooseItem.attribute.size != "") {
        if (
          !options.value.size.find((item) => {
            return item.value == chooseItem.attribute.size;
          })
        ) {
          if (chooseItem.__show.size_name != "") {
            options.value.size.push({
              label: chooseItem.__show.size_name,
              value: chooseItem.attribute.size,
            });
          } else {
            chooseItem.attribute.size = "";
          }
        }
      }

      const tempFormItem = {
        brand_id: chooseItem.attribute.brand_id,
        brand_article_no: chooseItem.attribute.brand_article_no,
        brand_name: chooseItem.__show.brand_name,
        name: chooseItem.attribute.name,
        name_en: chooseItem.attribute.name_en,
        price: chooseItem.price.original_price,
        size: chooseItem.attribute.size,
        size_name: chooseItem.__show.size_name,
        hs_code_DE: chooseItem.attribute.hs_code.DE,
        material: chooseItem.attribute.material,
        gtin: chooseItem.attribute.gtin,
        country_of_origin: chooseItem.attribute.country_of_origin,
        merchant_article_no: chooseItem.attribute.merchant_article_no,
      };

      for (let i = 0; i < formData.value.length; i++) {
        if (formData.value[i].item_id == item_id) {
          // 覆盖对应值
          formData.value[i].sku_info.brand_id == ""
            ? (formData.value[i].sku_info.brand_id = tempFormItem.brand_id)
            : null;
          formData.value[i].sku_info.__show.brand_name == ""
            ? (formData.value[i].sku_info.__show.brand_name =
                tempFormItem.brand_name)
            : null;
          formData.value[i].sku_info.brand_article_no == ""
            ? (formData.value[i].sku_info.brand_article_no =
                tempFormItem.brand_article_no)
            : null;
          formData.value[i].sku_info.name == ""
            ? (formData.value[i].sku_info.name = tempFormItem.name)
            : null;
          formData.value[i].sku_info.name_en == ""
            ? (formData.value[i].sku_info.name_en = tempFormItem.name_en)
            : null;
          formData.value[i].sku_info.price.original_price == 0
            ? (formData.value[i].sku_info.price.original_price =
                tempFormItem.price)
            : null;
          formData.value[i].sku_info.size == ""
            ? (formData.value[i].sku_info.size = tempFormItem.size)
            : null;
          formData.value[i].sku_info.__show.size_name == ""
            ? (formData.value[i].sku_info.__show.size_name =
                tempFormItem.size_name)
            : null;
          formData.value[i].sku_info.hs_code.DE == ""
            ? (formData.value[i].sku_info.hs_code.DE = tempFormItem.hs_code_DE)
            : null;
          formData.value[i].sku_info.material == ""
            ? (formData.value[i].sku_info.material = tempFormItem.material)
            : null;
          formData.value[i].sku_info.gtin == ""
            ? (formData.value[i].sku_info.gtin = tempFormItem.gtin)
            : null;
          formData.value[i].sku_info.country_of_origin == ""
            ? (formData.value[i].sku_info.country_of_origin =
                tempFormItem.country_of_origin)
            : null;
          formData.value[i].sku_info.pos_article_no == ""
            ? (formData.value[i].sku_info.pos_article_no =
                tempFormItem.merchant_article_no)
            : null;

          refushSingleCardFlagChange([i]);
        }
      }
    };

    /*
     *监听是否有改变
     */
    const hasChange = ref<boolean>(false);
    const itemChange = ref<Array<boolean>>([]);
    const itemChangeInit = () => {
      itemChange.value.splice(0, itemChange.value.length);
      for (let i = 0; i < formData.value.length; i++) {
        itemChange.value.push(false);
      }
    };
    watch(
      () => formData,
      () => {
        if (
          md5(JSON.stringify(formData.value)) ==
          md5(JSON.stringify(rawData.value?.relation_items))
        ) {
          hasChange.value = false;
        } else {
          hasChange.value = true;
        }
        for (let i = 0; i < itemChange.value.length; i++) {
          if (
            md5(JSON.stringify(formData.value[i])) ==
            md5(JSON.stringify(rawData.value?.relation_items[i]))
          ) {
            itemChange.value[i] = false;
          } else {
            itemChange.value[i] = true;
          }
        }
      },
      { deep: true }
    );

    /*
     *保存SKU
     */
    const saveButtonLoading = ref(false);
    const saveMultipleSKU = () => {
      const indexArray = itemChange.value
        .map((value, index) => {
          if (value == true) {
            return index;
          } else {
            return null;
          }
        })
        .filter((value) => {
          return value != null;
        }) as Array<number>;
      // 唤起子组件的必填验证
      for (let i = 0; i < indexArray.length; i++) {
        itemValid.value.splice(indexArray[i], 1, true);
      }
      setTimeout(() => {
        for (let i = 0; i < indexArray.length; i++) {
          itemValid.value.splice(indexArray[i], 1, false);
          //在这里可以根据itemPassed判断是否验证通过，阻止提交，但是目前保存操作，即使必填项未填，也不阻止提交
        }
        //调用保存
        // console.log("save", indexArray);
        saveSKU(indexArray);
      }, 400);
    };

    /*
     *保存SKU的方法
     */
    const saveSKU = (indexArray: Array<number>) => {
      const submitItem: any = [];
      for (let i = 0; i < indexArray.length; i++) {
        if (itemPassed[i] == false || SKUCardLoading.value[i]) {
          return;
        }
        const submitData = _.cloneDeep(formData.value[indexArray[i]]);

        let size = "";
        if (isNoSizeSku(submitData)) {
          size = "default";
        } else {
          size = submitData.sku_info.__show.size_name;
        }

        submitItem.push({
          item_id: submitData.item_id,
          position: submitData.position,
          sku_id: submitData.sku_info.sku_id,
          sku_info: {
            sku: submitData.sku_info.sku,
            name: submitData.sku_info.name,
            name_en: submitData.sku_info.name_en,
            brand_id: submitData.sku_info.brand_id,
            brand_article_no: submitData.sku_info.brand_article_no,
            merchant_article_no: submitData.sku_info.pos_article_no,
            size: size,
            gtin: submitData.sku_info.gtin,
            country_of_origin: submitData.sku_info.country_of_origin,
            price: {
              original_price: submitData.sku_info.price.original_price,
            },
            material: submitData.sku_info.material,
            hs_code: {
              DE: submitData.sku_info.hs_code.DE,
            },
            customs_info: [
              {
                hs_code: submitData.sku_info.hs_code.DE,
                material: submitData.sku_info.material,
                country_iso_2: "DE",
              },
            ],
            attribute_set_id: submitData.sku_info.attribute_set_id,
          },
        });
      }

      saveButtonLoading.value = true;
      for (let i = 0; i < indexArray.length; i++) {
        SKUCardLoading.value[indexArray[i]] = true;
      }
      // console.log(submitItem);

      ApiDsSku.saveSKU({
        id: route.params.id as string,
        items: submitItem,
      })
        .then(({ data }) => {
          saveButtonLoading.value = false;
          for (let i = 0; i < indexArray.length; i++) {
            SKUCardLoading.value[indexArray[i]] = false;
          }
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              // 对于保存后的卡片，重新拉取远程数据回显，确保与远程数据一致，并且可以再次备份原始数据，用于对比本地和远程数据是否有改变
              refushSingleData(indexArray);
              return true;
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /*
     *待处理订单产品发布
     */
    const unsaved = () => {
      // 对比原始数据与当前页面的数据，检查是否已保存
      if (!hasChange.value) {
        return false;
      } else {
        // 有项目未保存弹框
        Swal.fire({
          text: t("dsSkuManagement.unsaved"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return true;
      }
    };
    const hasInvoiceException = () => {
      // 检查是否有发票异常
      if (
        detailsData.value?.attachment_items.some((item) => {
          return item.is_exception == 10;
        }) ||
        detailsData.value?.is_exception == 10
      ) {
        // 有发票异常弹框
        Swal.fire({
          text: t("dsSkuManagement.hasInvoiceException"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return true;
      } else {
        return false;
      }
    };

    /*
     *发布订单
     */
    const publishButtonLoading = ref(false);
    const publishAllSKU = () => {
      //loading状态
      for (let i = 0; i < SKUCardLoading.value.length; i++) {
        SKUCardLoading.value.splice(i, 1, true);
      }
      detailsLoading.value = true;
      publishButtonLoading.value = true;

      //验证是否保存、是否有发票异常
      if (unsaved() || hasInvoiceException()) {
        for (let i = 0; i < SKUCardLoading.value.length; i++) {
          SKUCardLoading.value.splice(i, 1, false);
        }
        detailsLoading.value = false;
        publishButtonLoading.value = false;
        return;
      }
      // 必填项验证
      for (let i = 0; i < itemValid.value.length; i++) {
        itemValid.value.splice(i, 1, true);
      }
      setTimeout(() => {
        for (let i = 0; i < itemValid.value.length; i++) {
          itemValid.value.splice(i, 1, false);
        }
        if (
          itemPassed.value.some((item) => {
            return item == false;
          })
        ) {
          const falseIndexes: number[] = [];
          itemPassed.value.forEach((item, index) => {
            if (!item) {
              falseIndexes.push(index + 1);
            }
          });
          const falseIndexesString = falseIndexes.join(",");
          // 必填项验证错误弹框
          Swal.fire({
            html: `${t("common.requiredValidationError")}<br>
            ${t("dsSkuManagement.position")}：${falseIndexesString} `,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          for (let i = 0; i < SKUCardLoading.value.length; i++) {
            SKUCardLoading.value.splice(i, 1, false);
          }
          detailsLoading.value = false;
          publishButtonLoading.value = false;
          // 将页面滚动到发生验证错误的位置
          setTimeout(() => {
            const element = document.getElementById(
              `single-SKU-card-${falseIndexes[0]}`
            );
            if (element) {
              const rect = element.getBoundingClientRect();
              window.scrollTo({
                top: rect.top - 80 + window.scrollY,
                behavior: "smooth", // 平滑滚动
              });
            }
          });
          return;
        } else {
          //全部通过，调用接口发布
          if (detailsData.value) {
            const submitId = detailsData.value.id;

            ApiDsSku.publishAllSKU({ id: submitId })
              .then(({ data }) => {
                for (let i = 0; i < SKUCardLoading.value.length; i++) {
                  SKUCardLoading.value.splice(i, 1, false);
                }
                detailsLoading.value = false;
                publishButtonLoading.value = false;
                if (data.code == 0) {
                  showFormSubmitSuccessMsg(() => {
                    window.scrollTo(0, 0);
                    getDsSkuDetails(route.params.id as string, "all");
                  });
                } else {
                  showServerErrorMsg(data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }, 200);
    };

    /*
     *发票更新提醒
     */
    const hasNewInvoice = () => {
      const flag = detailsData.value?.attachment_items.some((item) => {
        return item.is_new == 1;
      });
      return flag;
    };

    /*
     *响应式布局
     */
    const isMobile = ref(store.getters.isMobile);
    watch(
      () => store.getters.isMobile,
      (newValue) => {
        isMobile.value = newValue;
      }
    );

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getCountryData();
    });

    return {
      t,
      dsSkuStatusClass,
      dsSkuStatusText,
      md5,
      getDsSkuDetails,
      detailsData,
      detailsLoading,
      mainCardLoading,
      SKUCardLoading,
      hasSKUCardLoading,
      formData,
      options,
      status,
      commonGalleryImages,
      formatDate,
      refushAllData,
      refushSingleData,
      refushItemExceptionData,
      formDataChange,
      downloadButtonLoading,
      downloadSkuImages,
      reportInvoiceButtonLoading,
      exceptionInvoiceParams,
      exceptionInvoiceDataChange,
      reportInvoiceButtonLoadingChange,
      saveButtonLoading,
      saveMultipleSKU,
      publishButtonLoading,
      publishAllSKU,
      exceptionProductParams,
      exceptionProductDataChange,
      searchParams,
      searchDataChange,
      chooseProduct,
      itemPass,
      itemNoPass,
      itemPassed,
      hasChange,
      itemValid,
      isMobile,
      refushAllCardFlag,
      refushSingleCardFlag,
      hasNewInvoice,
    };
  },
});
