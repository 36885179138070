import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a64a752"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "modal_report_product_exception",
  tabindex: "-1",
  "aria-hidden": "true",
  ref: "reportProductExceptionRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "w-100 d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "row w-100" }
const _hoisted_12 = { class: "col-md-3" }
const _hoisted_13 = { class: "py-6" }
const _hoisted_14 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_15 = { class: "fs-4 fw-bold" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "col-md-3" }
const _hoisted_19 = { class: "py-6" }
const _hoisted_20 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_21 = { class: "fs-4 fw-bold" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "col-md-3" }
const _hoisted_25 = { class: "py-6" }
const _hoisted_26 = { class: "fw-semibold fs-6 text-gray-400" }
const _hoisted_27 = { class: "fs-4 fw-bold" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "card-header" }
const _hoisted_31 = { class: "py-6 w-100 d-flex gap-14" }
const _hoisted_32 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_33 = ["checked"]
const _hoisted_34 = { for: "product_not_found" }
const _hoisted_35 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_36 = ["checked"]
const _hoisted_37 = { for: "product_invoice_not_found" }
const _hoisted_38 = {
  key: 0,
  class: "card-body"
}
const _hoisted_39 = { class: "table table-row-bordered" }
const _hoisted_40 = { class: "pb-6" }
const _hoisted_41 = { class: "row w-100" }
const _hoisted_42 = { class: "col-6 col-md-3" }
const _hoisted_43 = {
  key: 0,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_44 = {
  key: 1,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_45 = {
  key: 2,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_46 = {
  key: 3,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_47 = {
  key: 4,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_48 = {
  key: 5,
  class: "d-inline-block align-middle fw-bolder"
}
const _hoisted_49 = { class: "col-6 col-md-3 d-flex align-items-center" }
const _hoisted_50 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_51 = ["name", "id", "checked", "onClick"]
const _hoisted_52 = ["for"]
const _hoisted_53 = { class: "required mb-2" }
const _hoisted_54 = { class: "card-footer d-flex flex-wrap justify-content-end flex-max-sm-column max-sm-align-items-end gap-4 py-6 px-9" }
const _hoisted_55 = { class: "svg-icon svg-icon-1" }
const _hoisted_56 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_product_info = _resolveComponent("product-info")!
  const _component_el_input = _resolveComponent("el-input")!
  const _directive_auth = _resolveDirective("auth")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t("dsSkuManagement.reportProductException")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t("dsSkuManagement.brand")), 1),
                  _createElementVNode("div", _hoisted_15, [
                    (_ctx.exceptionData.brand != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.exceptionData.brand), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, "--"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.t("dsSkuManagement.brandArticleNo")), 1),
                  _createElementVNode("div", _hoisted_21, [
                    (_ctx.exceptionData.brand_article_no != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.exceptionData.brand_article_no), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_23, "--"))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.t("dsSkuManagement.ean")), 1),
                  _createElementVNode("div", _hoisted_27, [
                    (_ctx.exceptionData.gtin != '')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.exceptionData.gtin), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_29, "--"))
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("input", {
                  class: "form-check-input me-4",
                  type: "checkbox",
                  name: "product_not_found",
                  id: "product_not_found",
                  checked: _ctx.formData?.is_exception == 10,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.productNotFoundChange()))
                }, null, 8, _hoisted_33),
                _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.t("dsSkuManagement.productNotFound")), 1)
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("input", {
                  class: "form-check-input me-4",
                  type: "checkbox",
                  name: "product_invoice_not_found",
                  id: "product_invoice_not_found",
                  checked: _ctx.formData?.sku_invoice_exception == 10,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.invoiceMissingChange()))
                }, null, 8, _hoisted_36),
                _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.t("dsSkuManagement.invoiceMissing")), 1)
              ])
            ])
          ]),
          (
              _ctx.formData?.is_exception != 10 && _ctx.formData?.attachment_items.length
            )
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("table", _hoisted_39, [
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData?.attachment_items, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: `${_ctx.formData.id}_${_ctx.formData.item_id}_${item.id}_${item.file_id}`,
                        class: _normalizeClass({
                    error: item.rowErrorBorder == true,
                  })
                      }, [
                        _createElementVNode("td", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("div", _hoisted_42, [
                              _createVNode(_component_product_info, {
                                class: "w-50px d-inline-block align-middle me-4",
                                gallery: _ctx.commonGalleryImages([item.file_url])
                              }, null, 8, ["gallery"]),
                              (item.type == 'main_image')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_43, _toDisplayString(_ctx.t("dsSkuManagement.OverviewImage")), 1))
                                : _createCommentVNode("", true),
                              (item.type == 'brand_article_no')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.t("dsSkuManagement.brandArticleNoImage")), 1))
                                : _createCommentVNode("", true),
                              (item.type == 'material')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.t("dsSkuManagement.materialImage")), 1))
                                : _createCommentVNode("", true),
                              (item.type == 'size')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(_ctx.t("dsSkuManagement.sizeImage")), 1))
                                : _createCommentVNode("", true),
                              (item.type == 'ean')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_47, _toDisplayString(_ctx.t("dsSkuManagement.eanImage")), 1))
                                : _createCommentVNode("", true),
                              (item.type == 'origin_country')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(_ctx.t("dsSkuManagement.countryImage")), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_49, [
                              _createElementVNode("div", _hoisted_50, [
                                _createElementVNode("input", {
                                  class: "form-check-input me-4",
                                  type: "checkbox",
                                  name: `product_exception_${_ctx.formData.id}_${_ctx.formData.item_id}_${item.id}_${item.file_id}`,
                                  id: `product_exception_${_ctx.formData.id}_${_ctx.formData.item_id}_${item.id}_${item.file_id}`,
                                  checked: item.is_exception == 10,
                                  onClick: ($event: any) => (_ctx.formCheckboxChange(index))
                                }, null, 8, _hoisted_51),
                                _createElementVNode("label", {
                                  for: `product_exception_${_ctx.formData.id}_${_ctx.formData.item_id}_${item.id}_${item.file_id}`
                                }, _toDisplayString(_ctx.t("dsSkuManagement.exception")), 9, _hoisted_52)
                              ])
                            ]),
                            _withDirectives(_createElementVNode("div", {
                              class: _normalizeClass(["col-md-12 mt-4", { 'not-filled': item.notes == '' }])
                            }, [
                              _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.t("dsSkuManagement.exceptionNote")), 1),
                              _createVNode(_component_el_input, {
                                modelValue: item.notes,
                                "onUpdate:modelValue": ($event: any) => ((item.notes) = $event),
                                type: "textarea",
                                clearable: "",
                                size: "medium",
                                resize: "none",
                                placeholder: _ctx.t('common.plsEnter')
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                            ], 2), [
                              [_vShow, item.is_exception == 10]
                            ])
                          ])
                        ])
                      ], 2))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_54, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
            }, [
              _createElementVNode("span", _hoisted_55, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.cancel")), 1)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
            }, [
              _createElementVNode("span", _hoisted_56, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr064.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.submit")), 1)
            ])), [
              [_directive_auth, { auth: ['update'] }]
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ])
      ])
    ])
  ], 512))
}