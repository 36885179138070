export enum DsOrderStatus {
  New = 10,
  InProcessing = 15,
  Completed = 20,
  Closed = 30,
}
export enum ProductTemplate {
  Clothing = 1,
  BagSuitcase = 2,
  Jewelry = 3,
  Footwear = 4,
  Other = 5,
}
