import { BadgeStyle, NumberOrString } from "../type/common";
import { DsOrderStatus } from "../type/dsSkuManagement";
import { ProductTemplate } from "../type/dsSkuManagement";
import { DsSKUListItem } from "@/core/directive/interface/dsSkuManagement";
/**
 * @description: 获取DS订单状态（样式）
 * @return statusClass
 */
export const getDsOrderStatusClass = (status: NumberOrString): string => {
  let statusClass = "";

  switch (status) {
    case DsOrderStatus.New:
      statusClass = BadgeStyle.LightDanger;
      break;
    case DsOrderStatus.Completed:
      statusClass = BadgeStyle.LightSuccess;
      break;
    case DsOrderStatus.Closed:
      statusClass = BadgeStyle.Secondary;
      break;
    case DsOrderStatus.InProcessing:
      statusClass = BadgeStyle.LightWarning;
      break;
    default:
      statusClass = "hide";
      break;
  }
  return statusClass;
};

/**
 * @description: 获取DS订单状态（文本）
 * @return statusName
 */
export const getDsOrderStatusText = (status: NumberOrString): string => {
  let statusName = "";

  switch (status) {
    case DsOrderStatus.New:
      statusName = "new";
      break;
    case DsOrderStatus.Completed:
      statusName = "completed";
      break;
    case DsOrderStatus.Closed:
      statusName = "closed";
      break;
    case DsOrderStatus.InProcessing:
      statusName = "inProcessing";
      break;
    default:
      statusName = "";
      break;
  }
  return statusName;
};

/**
 * @description: 获取SKU品类（文字）
 * @return templateName
 */
export const getSkuProductTemplate = (status: NumberOrString): string => {
  let templateName = "";

  switch (status) {
    case ProductTemplate.Clothing:
      templateName = "clothing";
      break;
    case ProductTemplate.BagSuitcase:
      templateName = "bagsSuitcases";
      break;
    case ProductTemplate.Jewelry:
      templateName = "jewelry";
      break;
    case ProductTemplate.Footwear:
      templateName = "footwear";
      break;
    case ProductTemplate.Other:
      templateName = "other";
      break;
    default:
      templateName = "";
      break;
  }
  return templateName;
};

/**
 * @description: 判断该产品类目是不是没有size
 * @return Boolean
 */
export const isNoSizeSku = (skuItem: DsSKUListItem) => {
  // if (skuItem.sku_info.attribute_set_id == ProductTemplate.Jewelry) {
  //   return true;
  // } else if (skuItem.sku_info.attribute_set_id == ProductTemplate.Other) {
  //   return true;
  // } else {
  //   return false;
  // }
  return false;
};
