import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9243dc3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "modal fade",
  id: "modal_report_invoice_exception",
  tabindex: "-1",
  "aria-hidden": "true",
  ref: "reportInvoiceExceptionRef"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "w-100 d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_12 = ["checked"]
const _hoisted_13 = { for: "invoice_not_found" }
const _hoisted_14 = { class: "required mb-2" }
const _hoisted_15 = { class: "card-body py-0" }
const _hoisted_16 = { class: "table table-row-bordered" }
const _hoisted_17 = { class: "py-8" }
const _hoisted_18 = { class: "row w-100" }
const _hoisted_19 = { class: "col-md-3 d-flex gap-4" }
const _hoisted_20 = { class: "gallery__wrapper" }
const _hoisted_21 = {
  key: 0,
  class: "badge badge-danger"
}
const _hoisted_22 = { class: "d-inline-block align-middle fw-bolder" }
const _hoisted_23 = { class: "col-md-3 d-flex align-items-center" }
const _hoisted_24 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_25 = ["name", "id", "checked", "onClick"]
const _hoisted_26 = ["for"]
const _hoisted_27 = { class: "required mb-2" }
const _hoisted_28 = { class: "card-footer d-flex flex-wrap justify-content-end flex-max-sm-column max-sm-align-items-end gap-4 py-6 px-9" }
const _hoisted_29 = { class: "svg-icon svg-icon-1" }
const _hoisted_30 = { class: "svg-icon svg-icon-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_product_info = _resolveComponent("product-info")!
  const _directive_auth = _resolveDirective("auth")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.t("dsSkuManagement.reportInvoiceException")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["py-6 w-100", {
                error: _ctx.formData.rowErrorBorder == true,
              }])
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("input", {
                  class: "form-check-input me-4",
                  type: "checkbox",
                  name: "invoice_not_found",
                  id: "invoice_not_found",
                  checked: _ctx.formData.is_exception == 10,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.invoiceMissingChange()))
                }, null, 8, _hoisted_12),
                _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.t("dsSkuManagement.invoiceMissing")), 1)
              ]),
              _withDirectives(_createElementVNode("div", {
                class: _normalizeClass(["col-md-12 mt-4", { 'not-filled': _ctx.formData.notes == '' }])
              }, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t("dsSkuManagement.exceptionNote")), 1),
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.notes,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.notes) = $event)),
                  type: "textarea",
                  clearable: "",
                  size: "medium",
                  resize: "none",
                  placeholder: _ctx.t('common.plsEnter')
                }, null, 8, ["modelValue", "placeholder"])
              ], 2), [
                [_vShow, _ctx.formData.is_exception == 10]
              ])
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("table", _hoisted_16, [
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.attachment_items, (item, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: item.id,
                    class: _normalizeClass({
                    error: item.rowErrorBorder == true,
                  })
                  }, [
                    _createElementVNode("td", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_product_info, {
                              class: "w-50px d-inline-block align-middle me-4",
                              gallery: _ctx.commonGalleryImages([item.file_url])
                            }, null, 8, ["gallery"]),
                            (item.is_new == 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "NEW"))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_22, _toDisplayString(item.file_name.replace(/\.[^/.]+$/, "")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("input", {
                              class: "form-check-input me-4",
                              type: "checkbox",
                              name: `invoice_exception_${_ctx.formData.id}_${item.id}_${item.file_id}`,
                              id: `invoice_exception_${_ctx.formData.id}_${item.id}_${item.file_id}`,
                              checked: item.is_exception == 10,
                              onClick: ($event: any) => (_ctx.formCheckboxChange(index))
                            }, null, 8, _hoisted_25),
                            _createElementVNode("label", {
                              for: `invoice_exception_${_ctx.formData.id}_${item.id}_${item.file_id}`
                            }, _toDisplayString(_ctx.t("dsSkuManagement.invoiceException")), 9, _hoisted_26)
                          ])
                        ]),
                        _withDirectives(_createElementVNode("div", {
                          class: _normalizeClass(["col-md-12 mt-4", { 'not-filled': item.notes == '' }])
                        }, [
                          _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.t("dsSkuManagement.exceptionNote")), 1),
                          _createVNode(_component_el_input, {
                            modelValue: item.notes,
                            "onUpdate:modelValue": ($event: any) => ((item.notes) = $event),
                            type: "textarea",
                            clearable: "",
                            size: "medium",
                            resize: "none",
                            placeholder: _ctx.t('common.plsEnter')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                        ], 2), [
                          [_vShow, item.is_exception == 10]
                        ])
                      ])
                    ])
                  ], 2))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-light",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
            }, [
              _createElementVNode("span", _hoisted_29, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.cancel")), 1)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit()))
            }, [
              _createElementVNode("span", _hoisted_30, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr064.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.submit")), 1)
            ])), [
              [_directive_auth, { auth: ['update'] }]
            ])
          ])
        ])), [
          [_directive_loading, _ctx.loading]
        ])
      ])
    ])
  ], 512))
}